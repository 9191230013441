import React from "react";
import emailjs from "emailjs-com";
import { Link } from "@reach/router";
import Footer from "../components/footer";

export default function () {
  function sendEmail(e) {
    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_csfdEZiA",
        e.target,
        "user_zu7p2b3lDibMCDutH5hif"
      )
      .then(
        (result) => {
          console.log(result.text);
          success.classList.add("show");
          button.classList.add("show");
          failed.classList.remove("show");
        },
        (error) => {
          console.log(error.text);
          failed.classList.add("show");
        }
      );
  }

  return (
    <div>
      <section
        className="jumbotron breadcumb"
        style={{ /* backgroundImage: `url(${"./img/contact.jpg"})` */}}
      >
        <div className="mainbreadcumb">
          <div className="container-fluid">
            <div className="row m-10-hor">
              <div className="col-md-6">
                <h1>ติดต่อเรา</h1>
              </div>
              <div className="col-md-6">
                <div className="list">
                  <Link className="link" to="/home">
                    หน้าหลัก
                  </Link>
                  <span className="dash">/</span>
                  <span>ช่องทางการติดต่อ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid black_more">
        <div className="row m-10-hor">
          <div className="col-md-6">
            <div className="text-side">
              <h2>ทีมงานเพซดีไซน์ยินดีให้บริการ</h2>
              {/* <p>Vestibulum volutpat, lacus a ultrices sagittis,
              mi neque euismod dui, eu pulvinar nunc sapien ornare nisl.
              Phasellus pede arcu, dapibus eu, fermentum et,
              dapibus sed, urna.</p> */}

              <div className="address">
                <div className="heading">ออฟิศของเรา</div>
                <div className="list">
                  <i className="fa fa-map-marker"></i>
                  36/115 ถนนมอเตอร์เวย์ แขวงคลองสองต้นนุ่น เขตลาดกระบัง
                  กรุงเทพมหานคร
                </div>
                <div className="list">
                  <i className="fa fa-envelope-o"></i>
                  <a
                    href="mailto:contact@pace-design.co.th"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact@pace-design.co.th
                  </a>
                </div>
                <div className="list">
                  <i className="fa fa-phone"></i>
                  +6621717477
                </div>
              </div>
            </div>
          </div>
          {/* <div className='col-md-6'>
            <div className="form-side">
              <form className="formcontact" onSubmit={sendEmail}>
                <label>ชื่อ</label>
                <input type="text" name="user_name" required />
                <label>อีเมล</label>
                <input type="email" name="user_email" required />
                <label>ข้อควา</label>
                <textarea name="message" required />
                <div id='success' className='hide'>Your message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                <button type="submit" id='buttonsent'>
                  <span className="shine"></span>
                  <span>Send</span>
                </button>
              </form>
            </div>
          </div> */}
          <div className="col-md-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.793274780279!2d100.7297069154152!3d13.73096210147409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d66de04b58fd5%3A0x7701f041856bdeb5!2sPace%20Design%20Engineering%20Company%20Limited!5e0!3m2!1sen!2sth!4v1620927955312!5m2!1sen!2sth"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="btnround" onClick={() => window.open("/#", "_self")}>
            <span className="shine"></span>
            <span onClick={() => window.open("//lin.ee/De8PqQB", "_blank")}>
              ติดต่อผ่านไลน์ Official
            </span>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
}
