export default [
  {
    src: "/img/Rodenstock1.png",
    alt: "Start Gallery",
    caption: "Rodenstock",
    width: 812,
    height: 478
  },
  {
    src:
      "/img/Evergreen.png",
    alt: "Variations of passages",
    caption: "Evergreen",
    width: 195,
    height: 195
  },
  {
    src:
      "/img/UNDP.jpeg",
    alt: "Variations of passages",
    caption: "UNDP",
    width: 200,
    height: 200
  }
];
