import React from "react";
import { Link } from "@reach/router";
import Testimony from "../components/carouseltestimony";
import Footer from "../components/footer";

export default () => (
  <div className="wraperitem">
    <section
      className="jumbotron breadcumb"
      style={{ backgroundImage: `url(${"./img/testimony.jpg"})` }}
    >
      <div className="mainbreadcumb">
        <div className="container-fluid">
          <div className="row m-10-hor">
            <div className="col-md-6">
              <h1>บริการ</h1>
            </div>
            <div className="col-md-6">
              <div className="list">
                <Link className="link" to="/home">
                  หน้าหลัก
                </Link>
                <span className="dash">/</span>
                <span>บริการของเรา</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid" id="service">
      <div className="row m-10-hor">
        <div className="col-md-8">
          <div className="left">
            <div className="mainimg">
              <img src="./img/service.jpg" alt="imgservice" />
            </div>
            <div className="content">
              <div className="title">
                Modern <span className="color">Design</span>
              </div>
              <div className="desc">
                <p>
                  An international firm of architects, planners and interior
                  designers specialising in a wide range of commercial,
                  residential and public sector projects.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting{" "}
                </p>
                <blockquote>
                  Cum sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Duis mollis, est non commodo luctus,
                  nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                  Integer posuere erat a ante venenatis dapibus posuere velit
                  aliquet.
                </blockquote>
                <p>
                  Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                  venenatis vestibulum. Cum sociis natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Duis mollis,
                  est non commodo luctus, nisi erat porttitor ligula, eget
                  lacinia odio sem nec elit. Integer posuere erat a ante
                  venenatis dapibus posuere velit aliquet. Aenean eu leo quam.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="right">
            <ul className="services-list">
              <li>
                <Link to="/works">All Works</Link>
              </li>
              <li>
                <Link className="active" to="/service">
                  Modern Design
                </Link>
              </li>
              <li>
                <Link to="/service1">Interior</Link>
              </li>
              <li>
                <Link to="/service2">Architecture</Link>
              </li>
              <li>
                <Link to="/service3">Exterior</Link>
                <i className="fa fa-line" aria-hidden="true"></i>
              </li>
            </ul>
            <div className="bannerservice">
              <div className="imgbg">
                <img src="./img/bg-widget.jpg" alt="imgservice" />
              </div>
              <div className="contb">
                <div className="headingb">ขอใบเสนอราคา</div>
                <div className="descb">จากเซลล์ของเราโดยตรง</div>
                <div
                  className="btnround"
                  onClick={() => window.open("/#", "_self")}
                >
                  <span className="shine"></span>
                  <span
                    onClick={() => window.open("//lin.ee/De8PqQB", "_blank")}
                  >
                    <i className="fa fa-commenting-o" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Testimony />
    {/* <Footer /> */}
  </div>
);
