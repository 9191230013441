import React from 'react';
import Slider from '../components/sliderhome';
import Carouselteam from '../components/carouselteam';
import Bannercontact from '../components/bannercontact';
import Pricing from '../components/pricing';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';


export default () => (
  <div>
      <section className="jumbotron jumbomain">
          <Slider />
          <div className="icon-scroll-wraper">
            <div className="icon-scroll">
              <div className="icon-scroll-screen"></div>
            </div>
          </div>
      </section>

      {/* <section className='container-fluid p-0'>
        <div className='row'>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-1.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Concept Design
                </div>
                <div className='con-text'>
                  Qui ut ceteros comprehensam. Cu eos sale
sanctus eligendi, id ius elitr saperet,ocurreret
pertinacia pri an. No mei nibh consectetuer
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-2.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Interior
                </div>
                <div className='con-text'>
                  Qui ut ceteros comprehensam. Cu eos sale
sanctus eligendi, id ius elitr saperet,ocurreret
pertinacia pri an. No mei nibh consectetuer
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-3.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <div className='heading'>
                  Architecture
                </div>
                <div className='con-text'>
                  Qui ut ceteros comprehensam. Cu eos sale
sanctus eligendi, id ius elitr saperet,ocurreret
pertinacia pri an. No mei nibh consectetuer
                </div>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div>
              </div>
            </div>
          </div>

        </div>
      </section> */}


      <section className='container-fluid black pb-0'>
        <div className='row m-10-hor'>

        {/*
          <div className='col-md-5'>
            <div className='heading'>
              ทำไมต้องเลือกเรา
            </div>
          </div>
          */}

          <div className='col-md-7'>
            {/* <div className='content'>
              Curabitur mollis bibendum luctus.
              Duis suscipit vitae dui sed suscipit.
              Vestibulum auctor nunc vitae diam eleifend,
              in maximus metus sollicitudin. Quisque vitae sodales lectus.
              Nam porttitor justo sed mi finibus, vel tristique risus faucibus.
            </div> */}
            {/* <div className='content'>
              Curabitur mollis bibendum luctus.
              Duis suscipit vitae dui sed suscipit.
              Quisque vitae sodales lectus,
              vel tristique risus faucibus.
            </div> */}
          </div>

        </div>
    </section>

    <section className='container-fluid pt-0 black'>
        <div className='row m-10-hor'>
         {/* <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              01.
            </div>
            <div className='heading'>
              MODERN DESIGN
            </div>
            <div className='content'>
              Curabitur mollis bibendum luctus.
              Duis suscipit vitae dui sed suscipit.
              Vestibulum auctor nunc vitae lectus.
            </div>
          </div>
         </div> */}

         {/* <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              02.
            </div>
            <div className='heading'>
              FULLY SUPPORT
            </div>
            <div className='content'>
              Curabitur mollis bibendum luctus.
              Duis suscipit vitae dui sed suscipit.
              Vestibulum auctor nunc vitae lectus.
            </div>
          </div>
         </div> */}

         {/* <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              03.
            </div>
            <div className='heading'>
              MAINTENANCE SUPPORT
            </div>
            <div className='content'>
              Curabitur mollis bibendum luctus.
              Duis suscipit vitae dui sed suscipit.
              Vestibulum auctor nunc vitae lectus.
            </div>
          </div>
         </div> */}



          {/*
         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              1.
            </div>
            <div className='heading'>
              บริษัทมีทักษะที่ผ่านการฝึกฝนและเรียนรู้จากประสบการ์ณ ที่เกี่ยวข้องกับเครื่องสำรองไฟโดยเฉพาะแบรนด์ APC มานานกว่า 20 ปี
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              2.
            </div>
            <div className='heading'>
            เรามีทีมช่างผู้เชี่ยวชาญและน่าเชื่อถือที่ได้รับประกาศนียบัตรจากทางเจ้าของผลิตภัณท์ ให้บริการถึงหน้าไซด์งานของลูกค้า HBN products ทั่วประเทศไทย
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              3.
            </div>
            <div className='heading'>
            เรามีบริการติดตั้ง, ตรวจเช็คเครื่องสำหรับบำรุงรักษาและตรวจเช็คเครื่อง, บริการ AGS ต่างๆ และ บริการอื่นๆ
            </div>
          </div>
         </div>

         */}



        </div>
    </section>




{/*
    <section className='container-fluid themegradient'>
        <div className='row m-10-hor'>
          <div className='col-12 text-center'>
            <div className='subheading'>ลูกค้าของเรา</div>
            <div className='row'>
              <div className='col-md-8 mx-auto'>
              </div>
            </div>
          </div>
          <Carouselteam />
        </div>
    </section>
*/}

    {/* <Bannercontact/> */}
    {/* <Pricing /> */}
    {/* <Testimony /> */}
    {/* <Footer /> */}

  </div>
);
